/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";

type State = {
    container: {[x in string]: any}
}

type Context = State & {
    setValue: <T>(key: string, value: T) => void;
    flush: (key: string) => void;
}

type AddAction = {
    type: "add"
    payload: { key: string, value: any }
}

type RemoveAction = {
    type: "remove",
    key: string;
}

type Action = AddAction | RemoveAction;

const reducer = (state: State, action: Action): State => {
    switch(action.type) {
        case "add":  
            return {
                ...state,
                container: {
                    ...state.container,
                    [action.payload.key]: action.payload.value,
                }
            }
        case "remove": {
            const cloned = state;
            delete cloned.container[action.key];
            return cloned;
        }
    }
}

const Container = React.createContext<Context>({
    container: {},
    setValue: () => {},
    flush: () => {}
})


const MemoryStorageProvider = ({ children }: { children: React.ReactNode }) => {

    const [state, dispatch] = React.useReducer(reducer, { container: {}})

    const setValue = <T,>(key: string, value: T) => {
        dispatch({ type: "add", payload: { key, value }});
    }

    const flush = (key: string) => {
        dispatch({ type: "remove", key })
    }

    const value = React.useMemo(() => {
        return {
            container: state.container,
            setValue,
            flush,
        }
    }, [state])

    return <Container.Provider value={value}>
        {children}
    </Container.Provider>
}

export default MemoryStorageProvider;

const useMemory = () => React.useContext(Container);

export const useMemoryStorage = (key: string) => {
    const { container, setValue: orgSetValue, flush: orgFlush } = useMemory()

    return React.useMemo(() => ({
        value: container[key],
        setValue: <T,>(value: T) => orgSetValue(key, value),
        flush: () => orgFlush(key)
    }), [container[key]])
}